import dayjs from '@/plugins/dayjs'

const required = v => !!v || v === 0 || 'Required field'
const requiredBool = v => (v === true || v === false) || 'Please select an option'
const requiredCheckboxes = v => (v && v.length > 0) || 'At least one option is required'
const requiredEmail = v => (v && v.length > 0) || 'At least one email address is required'
const email = v => (v && /.+@.+\..+/.test(v)) || !v || 'Email must be valid'
const phone = v => (v && v.length === 14) || !v || 'Please enter a valid phone number'
const date = v => (v && v.length === 10 && dayjs(v, ['YYYY-MM-DD', 'MM/DD/YYYY'], 'es', true).isValid()) || !v || 'Please enter a valid date'
const password = v => (v && /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/.test(v)) || !v || 'Min 8 chars: upper, lower, number & special'
const dob = v => (dayjs().diff(dayjs(v), 'day') >= 0 && dayjs().diff(dayjs(v), 'year') < 150) || 'Please enter a valid date of birth'
const adult = v => (dayjs().diff(dayjs(v), 'day') >= 0 && dayjs().diff(dayjs(v), 'year') >= 18) || 'Pediatric: Please enter a valid adult date of birth'
const lastYear = v => dayjs(v).isSameOrAfter(dayjs().subtract(1, 'year'), 'day') && dayjs(v).isBefore(dayjs().add(1, 'day'), 'day') || 'Please enter a date from last year to today.'
const bloodPressure = v => (v && /^\d{1,3}\/\d{1,3}$/.test(v)) || !v || 'Enter a valid blood pressure'
const total100Percent = v => (v === null || v === 100) || 'Total 100%'
const total100PercentRequired = v => (v === 100) || 'Total 100%'

export default {
  email: [required, email],
  emailNotRequired: [email],
  password: [required, password],
  requiredCheckboxes: [requiredCheckboxes],
  requiredEmail: [requiredEmail],
  required: [required],
  requiredBool: [requiredBool],
  phone: [required, phone],
  phoneNotRequired: [phone],
  date: [required, date],
  dob: [required, date, dob],
  dobRequireAdult: [required, date, dob, adult],
  lastYear: [required, date, lastYear],
  bloodPressure: [bloodPressure],
  bloodPressureRequired: [required, bloodPressure],
  total100Percent: [total100Percent],
  total100PercentRequired: [total100PercentRequired],
  requiredMessage: msg => [v => !!v || v === 0 || msg],
  minLength: min => [v => (v && v.length >= min) || `Must be at least ${min} characters`],
  exactLength: length => [v => (v && v.length === length) || `Must be at exactly ${length} characters`],
  numberRange: (low, high) => [v => (!v || (v >= low && v <= high)) || `Range: ${low}-${high}`],
  numberRangeUTD: (low, high) => [v => (!v || v === 'UTD' || (v >= low && v <= high)) || `Range: ${low}-${high}`],
  minimum: minimum => [v => (!v || v >= minimum) || `Minimum: ${minimum}`],
  requiredNumberRange: (low, high) => [v => (!!v && v >= low && v <= high) || `Range: ${low}-${high}`],
  cantBeValue: (label, number) => [v => (!v || Number(v) !== Number(number)) || `${label} can't be ${number}`],
  maxLength: max => [v => (v || '').length <= max || `Maximum field length of ${max} characters`],
  maxEmails: max => [v => (v || '').length <= max || `Maximum of ${max} email addresses`],
  minWordCount: min => [v => (v && v.match(/\w+/g)?.length >= min) || `Minimum word count must be ${min}, count: [${v ? v?.match(/\w+/g)?.length : 0}]`],
}
